
import {mapActions,mapState} from 'vuex'
import DefaultLayout from './default.vue'
export default {
  extends: DefaultLayout,
  data() {
    return {
      step:3,
      showSubDomainName:false,
      companyOrIndividual:true,
      uploadedFiles:[],
      checkingSubDomain: false,
      suggestedDomainName: [],
      subdomainChecked: false,
      disabledSubdomainSubmit:false,
      desiredSubdomain:null,
      isValid:false,
      autofocus:false,
      name:null,
      loading:false,
      valid:true,
      imageUploading:false,
      nameRules: [
        v => !!v || this.$t('Name is required'),
      ],
      subDomainUrl:null,

      clipped: true,
      drawer: false,
      fixed: false,
      items: [
        {
          icon: 'mdi-view-dashboard',
          title: this.$t('Dashboard'),
          to: this.localeRoute('/dashboard/manager/main'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-image-filter-hdr',
          title: this.$t('Properties'),
          to: this.localeRoute('/dashboard/manager/properties'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-folder-wrench',
          title: this.$t('My Website'),
          to: this.localeRoute('/dashboard/manager/my-website'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-file',
          title: this.$t('Documents'),
          to: this.localeRoute('/dashboard/manager/documents'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-phone',
          title: this.$t('Contacts'),
          to: this.localeRoute('/dashboard/manager/contacts'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-cog',
          title: this.$t('General Settings'),
          to: this.localeRoute('/dashboard/manager/general-settings'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-file-document-outline',
          title: this.$t('Business Cards'),
          to: this.localeRoute('/dashboard/manager/business-card'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-wallet-giftcard',
          title: this.$t('e-Card Greetings'),
          to: this.localeRoute('/dashboard/manager/holiday-greetings'),
          badgeNumber: 0,
        },


         {
          icon: 'mdi-lock-outline',
          title: this.$t('Templates'),
          to: this.localeRoute('/dashboard/manager/templates'),
          badgeNumber: 0,
        },
         {
          icon: 'mdi-post',
          title: this.$t('Blog/Alerts'),
          to: this.localeRoute('/dashboard/manager/properdee-alerts'),
          badgeNumber: 0,
        },
         {
          icon: 'mdi-domain',
          title: this.$t('Want to Rent'),
          desc:'rent',
          to: this.localeRoute('/dashboard/manager/inquiry?propertyListingType=162'),
          badgeNumber: 0,
        },
         {
          icon: 'mdi-tag-outline',
          title: this.$t('Want to Buy'),
          desc:'buy',
          to: this.localeRoute('/dashboard/manager/inquiry?propertyListingType=161'),
          badgeNumber: 0,
        },

        {
          icon: 'mdi-view-dashboard-edit',
          title: this.$t('Enhance Photo'),
          to: this.localeRoute('/dashboard/manager/enhance-photo'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-account-box-outline',
          title: this.$t('AI Portrait'),
          to: this.localeRoute('/dashboard/manager/ai-portrait'),
          badgeNumber: 0,
        },
      ],
      items2: [

        {
          icon: 'mdi-calculator',
          title: this.$t('ProperDeeAgents'),
          to: '/',
        },


        {
          icon: 'mdi-file-document-outline',
          title: this.$t('Personal Website'),
          to: this.$i18n.locale=='en'?this.$auth.user.subDomain+'/en'+ '/manager':this.$auth.user.subDomain+ '/manager',
        },
        // {
        //   icon: 'mdi-lock-outline',
        //   title: this.$t('Insurance'),
        //   to: this.localeRoute('/dashboard/manager/insurance'),
        // },


      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: this.$t('Vuetify.js'),
      type: this.$route.params.type,
      timestamp: null,
      showInquiry:false,
      rentOrBuy:162
    }
  },

  watch: {
    '$route.path': {
      handler(val) {
        console.log('this.$route.path', this.$route.path)
        this.setNavMenu()
      },
      deep: true,
    },
    desiredSubdomain(val) {
      this.isValid = false
    },
  },
  computed: {
        ...mapState('taxonomy', ['taxonomy']),
    ...mapState('config/site', ['siteConfig']),
    color() {
      return ['warning', 'success'][this.validSubDomain]
    },
  },
  created() {
    this.setNavMenu()
    setInterval(() => {
      this.getNow()
    }, 1000)
    console.log(this.$auth.user)
    if(!this.$auth.user.subDomain&& this.$auth.user.showSubdomainPopup){
      this.showSubDomainName=true
    }
    if(this.$auth.user.package.proOrStarter){
      this.items.splice(4,0, {
          icon: 'mdi-format-list-bulleted',
          title: this.$t('Marketing'),
          to: this.localeRoute('/dashboard/manager/marketing'),
          badgeNumber: 0,
        },)
    }
  },
  mounted(){
      if (!this.$auth.user.subDomain && this.$auth.user.showSubdomainPopup) {
              console.log('set subdomain ===>', this.$auth.user.package.paymentUrl)
              this.showSubDomainName = true
        } else if(this.$auth.user.subDomain) {
          console.log('All set ===>', this.$auth.user.package.paymentUrl)
          this.showSubDomainName = false
        }else if(this.$auth.user.package.paymentUrl){
          console.log('redirect user', this.$auth.user.package.paymentUrl)
          this.$router.push(this.localePath(this.$auth.user.package.paymentUrl))
        }
  },
  methods: {
    ...mapActions('config/site', ['scrollTop', 'setErrorSnackbar']),
    ...mapActions('media', ['mediaUpload']),
    ...mapActions('user', [
      'updateBusinessName',
        'createSubDomain',
        'checkSubDomain',
        'updateUserSubDomain',
        'createCompany',
        'getUserProfile',
    ]),
    redirect(url){
      this.showSubDomainName=false
      this.$router.push(this.localeRoute(url))
    },
    openSubdomain(url){
          window.open(url, '_blank');
     },
    validate() {
        return this.$refs.companyUpdate.validate()
      },
      nextStep(){
        if(this.validate()&& this.$refs.imgUploader.getFiles().length){
          this.loading=true
          const obj={
            "isCompanyOrIndividual": this.companyOrIndividual,
            "name": this.name,
            "logo": this.$refs.imgUploader.getFiles()

          }
          this.updateBusinessName(obj).then(data=>{
          this.loading=false
            this.setErrorSnackbar({
              title: this.$t('Account Updated!'),
              msg: this.$t('Success!'),
              color: this.$vuetify.theme.themes.light.success,
            })
            this.step=6
          }).catch(err => {
            console.log("err",err)
            this.loading=false
            this.setErrorSnackbar({
              title: this.$t('Try Again!'),
              msg: err.response.data.message,
              color: this.$vuetify.theme.themes.light.error,
            })
            this.disabledSubdomainSubmit = false
              this.$nuxt.$loading.finish()
          })
        }else{
          this.setErrorSnackbar({
            title: this.$t('Name and image is required!'),
            msg: this.$t('Error!'),
            color: this.$vuetify.theme.themes.light.error,
          })
        }
      },
      checkSubdomain() {
        if (this.desiredSubdomain) {
          if (
            this.desiredSubdomain.match(/[^a-zA-Z0-9\-]/g) !== null &&
            this.desiredSubdomain.match(/[^a-zA-Z0-9\-]/g).length
          ) {
            this.setErrorSnackbar({
              title: this.$t('Name is invalid'),
              msg: this.$t('Error!'),
              color: this.$vuetify.theme.themes.light.error,
            })
            this.desiredSubdomain = null
          }
          this.checkingSubDomain = true
          this.subdomainChecked = true
          this.checkSubDomain({ subdomainName: this.desiredSubdomain }).then(
            data => {
              console.log(data)
              this.isValid = data.data.isValid
              this.suggestedDomainName = data.data.suggestSubdomainName
              this.checkingSubDomain = false
            }
          ).catch(err => {
            console.log("err",err)
            this.isValid=false
            this.checkingSubDomain = false
            this.setErrorSnackbar({
              title: this.$t('Try Again!'),
              msg: err.response.data.message,
              color: this.$vuetify.theme.themes.light.error,
            })
            this.disabledSubdomainSubmit = false
              this.$nuxt.$loading.finish()
          })
        }
      },
      makeValidTofalse() {
        this.subdomainChecked = false
      },
      submitSubDomainInfo() {
        this.$nuxt.$loading.start()
      this.disabledSubdomainSubmit = true
      this.makeValidTofalse()
        this.createSubDomain({ subdomainName: this.desiredSubdomain })
          .then(async data => {
            console.log("data submitSubDomainInfo",data)
            this.subDomainUrl=data.data
            this.setErrorSnackbar({
              title: this.$t('Subdomain Created!'),
              msg: this.$t('Success!'),
              color: this.$vuetify.theme.themes.light.success,
            })
            this.step = 4
            this.disabledSubdomainSubmit = false
            await this.getUserProfile()
              this.$nuxt.$loading.finish()
          })
          .catch(err => {
            console.log("err",err)
            this.setErrorSnackbar({
              title: this.$t('Try Again!'),
              msg: err.response.data.message,
              color: this.$vuetify.theme.themes.light.error,
            })
            this.disabledSubdomainSubmit = false
              this.$nuxt.$loading.finish()
          })

       },
    showInquiryDialog(ev){
      console.log("ev",ev)
      this.showInquiry=true
      this.rentOrBuy=ev==='rent'?162:161
    },
    logout() {
      this.$router.push(this.localeRoute('/logout-callback'))
    },
    setNavMenu() {
      console.log('setNavMenu', this.$route)
      if (this.type === 'tenant') {
        this.items = [
          {
            icon: 'mdi-view-dashboard',
            title: 'Dashboard',
            to: this.localeRoute('/dashboard/tenant/main'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-home-city-outline',
            title: 'Apply to Rent',
            to: this.localeRoute('/dashboard/tenant/apply-to-rent'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-file',
            title: 'Documents',
            to: this.localeRoute('/dashboard/tenant/documents'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-calculator',
            title: 'Accounting',
            to: this.localeRoute('/dashboard/tenant/accounting'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-message-reply-text',
            title: 'Messages',
            to: this.localeRoute('/dashboard/tenant/message'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-hammer-wrench',
            title: 'Maintenance',
            to: this.localeRoute('/dashboard/tenant/maintenance'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-credit-card-wireless-outline',
            title: 'Pay Rent Online',
            to: this.localeRoute('/dashboard/tenant/pay-rent-online'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-lock-outline',
            title: this.$t('Insurance'),
            to: this.localeRoute('/dashboard/tenant/insurance'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-cog',
            title: 'General Settings',
            to: this.localeRoute('/dashboard/tenant/general-settings'),
            badgeNumber: 0,
          },
        ]
        this.items2 = []
      }
      if (this.type === 'owner') {
        this.items = [
          {
            icon: 'mdi-view-dashboard',
            title: 'Dashboard',
            to: this.localeRoute('/dashboard/owner/main'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-file-document-outline',
            title: 'Report',
            to: this.localeRoute('/dashboard/owner/Report'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-file',
            title: 'Documents',
            to: this.localeRoute('/dashboard/owner/documents'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-calculator',
            title: 'Accounting',
            to: this.localeRoute('/dashboard/owner/accounting'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-account-multiple',
            title: 'Tenant(s)',
            to: this.localeRoute('/dashboard/owner/tenants'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-message-reply-text',
            title: 'Messages',
            to: this.localeRoute('/dashboard/owner/message'),
            badgeNumber: 0,
          },

          // {
          //   icon: 'mdi-credit-card-wireless-outline',
          //   title: 'Pay Bill Online',
          //   to: this.localeRoute('/dashboard/owner/pay-bill-online'),
          //   badgeNumber: 0,
          // },
          {
            icon: 'mdi-lock-outline',
            title: this.$t('Insurance'),
            to: this.localeRoute('/dashboard/owner/insurance'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-cog',
            title: 'General Settings',
            to: this.localeRoute('/dashboard/owner/general-settings'),
            badgeNumber: 0,
          },
        ]
        this.items2 = []
      }
      if (this.type === 'admin') {
        this.items = [
          {
            icon: 'mdi-view-dashboard',
            title: 'Dashboard',
            to: this.localeRoute('/dashboard/admin/main'),
            badgeNumber: 0,
          },

          {
            icon: 'mdi-table-account',
            title: 'All Subscribers',
            to: this.localeRoute(
              '/dashboard/admin/user?type=0&name=Subscribers&subscriptionType=0'
            ),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-home-group-plus',
            title: 'All Properties',
            to: this.localeRoute('/dashboard/admin/properties'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-alpha-i-box',
            title: 'All Inquiry ',
            to: this.localeRoute('/dashboard/admin/inquiry'),
            badgeNumber: 0,
          },


          {
            icon: 'mdi-video',
            title: 'All Videos',
            to: this.localeRoute('/dashboard/admin/videos'),
            badgeNumber: 0,
          },

          {
            icon: 'mdi-text-box-outline',
            title: 'All template',
            to: this.localeRoute('/dashboard/admin/templates'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-account-group-outline',
            title: 'All Users',
            to: this.localeRoute('/dashboard/admin/user?type=0&name=All User'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-credit-card-outline',
            title: 'Payment History',
            to: this.localeRoute('/dashboard/admin/payment-history'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-email-outline',
            title: 'Emails',
            to: this.localeRoute('/dashboard/admin/emails-popups?type=1'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-forum-outline',
            title: 'Popups',
            to: this.localeRoute('/dashboard/admin/emails-popups?type=2'),
            badgeNumber: 0,
          },

          {
            icon: 'mdi-flower-poppy',
            title: 'Properdee Alerts',
            to: '/dashboard/admin/ProperdeeAlerts',
            badgeNumber: 0,
          },

          {
            icon: 'mdi-account-multiple-plus-outline',
            title: 'Admin List',
            to: this.localeRoute(
              '/dashboard/admin/user?type=1&name=Admin List'
            ),
            badgeNumber: 0,
          },

          {
            icon: 'mdi-percent-circle-outline',
            title: 'Discount Lists',
            to: this.localeRoute('/dashboard/admin/discount-lists'),
            badgeNumber: 0,
          },

          {
            icon: 'mdi-finance',
            title: 'Analytics',
            to: this.localeRoute('/dashboard/admin/analytics'),
            badgeNumber: 0,
          },
          {
            icon: 'mdi-cake-variant-outline',
            title: 'E-Card Greetings Lists',
            to: this.localeRoute('/dashboard/admin/holiday-greetings-lists'),
            badgeNumber: 0,
          },
          {
          icon: 'mdi-web-check',
          title: this.$t('Custom Website'),
          to: this.localeRoute('/dashboard/admin/custom-websites'),
          badgeNumber: 0,
        },
          {
          icon: 'mdi-face-agent',
          title: this.$t('Recommended Agents'),
          to: this.localeRoute('/dashboard/admin/recommended-agents'),
          badgeNumber: 0,
        },
          {
            icon: 'mdi-cog-outline',
            title: 'General Settings',
            to: this.localeRoute('/dashboard/admin/general-settings'),
            badgeNumber: 0,
          },

        ]
        this.items2 = []
      }
      if (this.type === 'bahtsold') {
        this.items = [
          {
            icon: 'mdi-view-dashboard',
            title: this.$t('Dashboard'),
            to: this.localeRoute('/dashboard/bahtsold/main'),
            badgeNumber: 0,
          },
          {
          icon: 'mdi-image-filter-hdr',
          title: this.$t('Properties'),
          to: this.localeRoute('/dashboard/bahtsold/properties'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-phone',
          title: this.$t('Contacts'),
          to: this.localeRoute('/dashboard/bahtsold/main'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-file',
          title: this.$t('Documents'),
          to:  this.localeRoute('/dashboard/bahtsold/main'),
          badgeNumber: 0,
        },
         {
          icon: 'mdi-folder-wrench',
          title: this.$t('My Website'),
          to:  this.localeRoute('/dashboard/bahtsold/main'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-wallet-giftcard',
          title: this.$t('e-Card Greetings'),
          to:  this.localeRoute('/dashboard/bahtsold/main'),
          badgeNumber: 0,
        },

        {
          icon: 'mdi-file-document-outline',
          title: this.$t('Business Cards'),
          to:  this.localeRoute('/dashboard/bahtsold/main'),
          badgeNumber: 0,
        },
         {
          icon: 'mdi-lock-outline',
          title: this.$t('Templates'),
          to:  this.localeRoute('/dashboard/bahtsold/main'),
          badgeNumber: 0,
        },
        {
          icon: 'mdi-cog',
          title: this.$t('General Settings'),
          to:  this.localeRoute('/dashboard/bahtsold/main'),
          badgeNumber: 0,
        },
        ]
        this.items2 = [
           {
            icon: 'mdi-calculator',
            title: this.$t('ProperDee Home'),
            to: 'https://properdee.com',
          },
        ]
      }
    },
    getNow: function () {
      const today = new Date()
      const time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
      const dateTime = time
      this.timestamp = dateTime
    },
    copyToClipboard() {
      const text = this.$auth.user.subDomain;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).then(() => {
          this.setErrorSnackbar({
              title: this.$t('Website copied to clipboard!'),
              msg:'Success',
              color: this.$vuetify.theme.themes.light.success,
            })
          // alert('Text copied to clipboard');
        }).catch(err => {
          console.error('Failed to copy text: ', err);
        });
      } else {
        // Fallback for browsers that do not support the Clipboard API
        const textarea = document.createElement('textarea');
        textarea.value = text;
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand('copy');
          this.setErrorSnackbar({
              title: this.$t('Website copied to clipboard!'),
              msg:'Success',
              color: this.$vuetify.theme.themes.light.success,
            })
        } catch (err) {
          console.error('Failed to copy text: ', err);
        }
        document.body.removeChild(textarea);
      }
    }
  },
}
