const controller = '/manager/dashboard'
export default axios => ({
  postToPartnersWebsites(obj) {
    return axios.post(`${controller}/add-properties/${obj.id}/partner-website`, obj.postTo)
  },
  addProperty(obj) {
    return axios.post(`${controller}/add-properties`, obj)
  },
  getAllProperties(obj) {
    console.log('getAllProperties', obj)
    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`
    if (obj.search) {
      parmas = parmas + `&search=${obj.search}`
    }
    return axios.get(`${controller}/get-properties${parmas}`)
  },
  getPropertyById(obj) {
    return axios.get(`${controller}/get-properties/${obj.propertyId}`)
  },
  getPropertyMarketingById(obj) {
    return axios.get(`${controller}/get-properties/${obj.propertyId}/marketing`)
  },
  getPropertyByIdForEdit(obj) {
    return axios.get(`${controller}/get-properties-edit/${obj.propertyId}`)
  },
  getPropertyForTenant(obj) {
    return axios.get(`${controller}/get-properties-name`)
  },
  deletePropertyById(obj) {
    return axios.delete(`${controller}/delete-properties/${obj.propertyId}`)
  },
  updatePropertyById(obj) {
    return axios.put(`${controller}/update-properties/${obj.id}`, obj)
  },

  getListTanent(obj) {
    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`
    if (obj.search) {
      parmas = parmas + `&search=${obj.search}`
    }
    if (obj.fromDocument) {
      parmas = parmas + `&fromDocument=${obj.fromDocument}`
    }
    return axios.get(`${controller}/get-tenant${parmas}`)
  },
  getTanentById(obj) {
    return axios.get(`${controller}/get-tenant/${obj.tenantId}`, obj)
  },
  deleteTanentById(obj) {
    return axios.delete(`${controller}/delete-tenant/${obj.tenantId}`, obj)
  },
  addTanent(obj) {
    return axios.post(`${controller}/add-tenant`, obj)
  },
  updateTanent(obj) {
    return axios.put(
      `${controller}/update-tenant/${obj.tenantId}`,
      obj.tenantObj
    )
  },
  searchForTenantByEmail(obj) {
    return axios.post(`${controller}/search-for-tenant`, obj)
  },

  getListOwners(obj) {
    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`
    if (obj.search) {
      parmas = parmas + `&search=${obj.search}`
    }
    return axios.get(`${controller}/get-owner${parmas}`)
  },
  getOwnerById(obj) {
    return axios.get(`${controller}/get-owner/${obj.ownerId}`, obj)
  },
  deleteOwnerById(obj) {
    console.log('deleteOwnerById', obj)
    return axios.delete(`${controller}/delete-owner/${obj.ownerId}`, obj)
  },
  addOwner(obj) {
    return axios.post(`${controller}/add-owner`, obj)
  },
  updateOwner(obj) {
    return axios.put(`${controller}/update-owner/${obj.id}`, obj.ownerObj)
  },

  getVendorList(obj) {
    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}&type=${obj.type}`
    if (obj.services) {
      parmas = parmas + `&services=${obj.services}`
    }
    console.log(obj)
    return axios.get(`${controller}/get-vendor${parmas}`, obj)
  },
  addVendor(obj) {
    return axios.post(`${controller}/add-vendor`, obj)
  },
  getVendor(obj) {
    return axios.get(`${controller}/get-vendor/${obj.id}`, obj)
  },
  deleteVendor(obj) {
    return axios.delete(`${controller}/delete-vendor/${obj.id}`, obj)
  },
  updateVendor(obj) {
    return axios.put(`${controller}/update-vendor/${obj.id}`, obj)
  },

  getMaintenanceList(obj) {
    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`
    if (obj.search) {
      parmas = parmas + `&search=${obj.search}`
    }
    console.log(obj)
    return axios.get(`${controller}/get-maintenance${parmas}`, obj)
  },
  getVendorListForMaintenance(obj) {
    return axios.get(`${controller}/get-vendor-name`, obj)
  },
  getMaintenance(obj) {
    return axios.get(`${controller}/get-maintenance/${obj.id}`, obj)
  },
  deleteMaintenance(obj) {
    return axios.delete(`${controller}/delete-maintenance/${obj.id}`)
  },
  addMaintenance(obj) {
    return axios.post(
      `${controller}/add-maintenance?sendEmail=${obj.type}`,
      obj.obj
    )
  },
  updateMaintenance(obj) {
    return axios.put(
      `${controller}/update-maintenance/${obj.id}?sendEmail=${obj.type}`,
      obj.obj
    )
  },
  completedMaintenance(obj) {
    return axios.put(`${controller}/update-maintenance/${obj.id}/completed`)
  },
  notifyTenantsMaintenance(obj) {
    return axios.put(
      `${controller}/update-maintenance/${obj.id}/notify-tenants`
    )
  },
  previewMaintenance(obj) {
    return axios.post(`${controller}/add-maintenance/preview-email`, obj)
  },
  addcontact(obj) {
    return axios.post(`${controller}/add-contact`, obj)
  },
  updateContact(obj) {
    return axios.put(`${controller}/update-contact/${obj.id}`, obj.obj)
  },
  getContactList(obj) {
    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`
    if (obj.type) {
      parmas = parmas + `&type=${obj.type}`
    }
    if (obj.search) {
      parmas = parmas + `&search=${obj.search}`
    }
    return axios.get(`${controller}/get-contact${parmas}`)
  },
  getContact(obj) {
    return axios.get(`${controller}/get-contact/${obj.id}`)
  },
  deleteContact(obj) {
    return axios.delete(`${controller}/delete-contact/${obj.id}`)
  },
  sendRequestForAdmin(obj) {
    return axios.post(`${controller}/my-website/add-admin/`, obj)
  },

  addHolidayGreeting(obj) {
    return axios.post(
      `${controller}/add-holiday-greeting?sendEmail=${obj.sendEmail}`,
      obj.obj
    )
  },
  getListOfHolidayGreetings(obj) {
    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}&autoSend=${obj.autoSend}`

    return axios.get(`${controller}/get-holiday-greeting${parmas}`)
  },
  getListOfHolidayGreetingsGallery(obj) {
    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}&category=${obj.category}`

    return axios.get(`${controller}/get-holiday-greeting-gallery${parmas}`)
  },
  deleteHolidayGreeting(obj) {
    return axios.delete(`${controller}/delete-holiday-greeting/${obj.id}`)
  },
  resendCard(obj) {
    return axios.put(`${controller}/resend-holiday-greeting?eCardId=${obj.id}`)
  },
  getHolidayCategoryText(obj) {
    return axios.get(
      `${controller}/get-holiday-greeting-sample-text?category=${obj.id}&language=${obj.language}`
    )
  },
  getHolidayCardDetail(obj) {
    return axios.get(`${controller}/get-holiday-greeting/${obj.cardId}`)
  },
  updateCard(obj) {
    return axios.put(
      `${controller}/update-holiday-greeting/${obj.obj.id}?sendEmail=${obj.sendEmail}`,
      obj.obj
    )
  },
  getMediaLib(obj) {
    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`

    return axios.get(`${controller}/get-my-photo-library${parmas}`)
  },
  deleteImageFromLib(obj) {
    return axios.delete(`${controller}/delete-my-photo-library/${obj.id}`)
  },
  getPropertyForReport(obj) {
    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`

    return axios.get(`${controller}/get-properties-by-report${parmas}`)
  },
  getPropertyForReportSummary(obj) {
    return axios.post(`${controller}/get-report-details`, obj)
  },
  addReport(obj) {
    return axios.post(`${controller}/add-report`, obj)
  },
  previewReport(obj) {
    return axios.post(`${controller}/preview-report`, obj)
  },

  getYearForReportByProperty(obj) {
    return axios.get(`${controller}/get-report-year/${obj.id}`, obj)
  },
  getMonthForReportByProperty(obj) {
    return axios.get(
      `${controller}/get-report-month/${obj.id}?year=${obj.year}`,
      obj
    )
  },
  getListOfApplicationsForProperty(obj) {
    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`
    if (obj.search) {
      parmas = parmas + `&search=${obj.search}`
    }
    return axios.get(
      `${controller}/get-applications/${obj.propertyId}${parmas}`
    )
  },
  getDocumentsData(obj) {
    return axios.get(
      `${controller}/document/get-document?type=${obj.type}&propertyOrTenant=${obj.propertyOrTenant}&propertyOrTenantId=${obj.propertyOrTenantId}&documentId=${obj.documentId}`
    )
  },
  getPropertiesSummary(obj) {
    return axios.get(`${controller}/get-properties-summary/${obj.id}`)
  },
  getTemplates(obj) {
    return axios.get(`${controller}/templates?lang=${obj.lang}`)
  },
  getApplicationSummary(obj) {
    return axios.get(`${controller}/get-applications-details/${obj.id}`)
  },
  approveApplications(obj) {
    return axios.put(`${controller}/approve-applications/${obj.id}`)
  },
  declineApplications(obj) {
    return axios.put(`${controller}/decline-applications/${obj.id}`)
  },
  setUpPaymentGateway(obj) {
    return axios.post(`${controller}/set-up-payment-gateway/`,obj)
  },
  getInquiryList(obj) {
    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`
    if (obj.search) {
      parmas = parmas + `&search=${obj.search}`
    }
    return axios.post(`${controller}/inquiry/search${parmas}`,obj)
  },
  markReadInquiry(obj) {
    return axios.put(`${controller}/inquiry/${obj.id}?read=${obj.read}`,obj)
  },
  deleteEnquiry(obj) {
    return axios.delete(`${controller}/inquiry/${obj.id}`,obj)
  },
  getInquiryNumber(obj) {
    return axios.get(`${controller}/inquiry/unread-number`)
  },
  getInquiryDates(obj) {
    return axios.get(`${controller}/inquiry-date`)
  },
  clearAllInquiryDates(obj) {
    return axios.delete(`${controller}/inquiry-date`)
  },
  createBusinessCard(obj) {
    return axios.post(`${controller}/business-cards`,obj)
  },
  getBusinessCard(obj) {
    return axios.get(`${controller}/business-cards`,obj)
  },
  getInfoForBusinessCard(obj) {
    return axios.get(`${controller}/business-cards/system-information`,obj)
  },
  getInfoForBusinessCardEdit(obj) {
    return axios.get(`${controller}/business-cards/details`,obj)
  },
  getImageForEdit(obj) {
    return axios.get(`${controller}/enhance-photos`,obj)
  },
  uploadImageForEdit(obj) {
    return axios.post(`${controller}/enhance-photos`,obj)
  },
  deleteImageForEdit(obj) {
    return axios.delete(`${controller}/enhance-photos/${obj.id}`,obj)
  },
  getListOfBusinessCard(obj) {
    let parmas = `?itemsPerPage=${obj.itemsPerPage}&page=${obj.page}`
    return axios.get(`${controller}/generated-business-cards/${parmas}`)
  },
  deleteBusinessCard(obj) {
    return axios.delete(`${controller}/generated-business-cards/${obj.id}`)
  },
})
